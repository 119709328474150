<template>
    <div class="content">
     <van-popup style="padding: 0 5px;" position="left"  @click-overlay="onClickOverlay" v-model="asideFlags" :style="{ height: '100%',width:'60%' }">
      <div class="list">
        <div class="vanCell" @click="toRouter('/invite')">
          <p>{{$t('index.invite')}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" @click=" $router.push('/personal')">
          <p>{{$t('index.Individual')}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" @click="toRouter('/pledge')">
          <p>{{$t('index.pledges')}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" @click=" $router.push('/activities')">
          <p>{{$t('index.Activity')}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" @click="toRouter('/mining')">
          <p>{{$t("index.mining")}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" @click="$router.push('/answer')">
          <p>{{$t("index.answer")}}</p>
          <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px"
        />
        </div>
        <div class="vanCell" style="border-bottom:0px;" @click="$router.push('/property')">
          <p>{{$t("index.property")}}</p>
          <div class="totalAmount"> 
            <p style="margin-right:5px;">${{totalAmount?Number(totalAmount).toFixed(2) :0}}</p>
            <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/xxImage/index/Snipaste_2023-05-25_14-27-40.png')"
          style="margin-right: 16px;vertical-align: middle;"
        />
          </div>
        </div>
      </div>
    </van-popup>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import mixin from "../../common/web3";
  export default {
    name: "NAVBAR",
    mixins: [mixin],
    inject: ["reload"],
    props:{
      shows:{
        type:Boolean,
      }
    },
    data() {
      return {
        jhshowt:true,
        asideFlags:false,
        title: "",
        langIndex: 0,
        showLang: false, //选择语言下拉框
        showConnect: false, //连接钱包成功后用户下拉框
        langList: [
          {
            index: 0,
            text: "English",
            icon: require("@/assets/en@2x.png"),
          },
          {
            index: 1,
            text: "中文",
            icon: require("@/assets/zh@2x.png"),
          },
        ],
        // devotokens: "",
      };
    },
    computed: {
      //vuex取值
      ...mapState({
        walletAddress: (state) => state.walletAddress,
        isLinkBsc: (state) => state.isLinkBsc,
      }),
      devotokens() {
        return this.$storage.get("devotokens");
      },
      lang() {
        return this.$storage.get("lang");
      },
      connectList() {
        return [
          {
            index: 1,
            text: "断开连接",
            icon: require("@/assets/cls/break.png"),
          },
        ];
      },
      notconnectList() {
        return [
          {
            index: 0,
            text: "Please log in with BSC",
            icon: "",
          },
          {
            index: 1,
            text: "Drop out",
            icon: require("@/assets/external@2x.png"),
          },
        ];
      },
      totalAmount() {
        return this.$storage.get("totalAmount");
      }
    },
    watch:{
      asideFlags: function (){
        if (this.asideFlags == true) {
          this.$axios.get(this.$api.myEarn, {}).then((res) => { //查询总资产
              this.$store.commit("settotalAmount", res.data.totalAmount);
          });
        }
      }
    },
    mounted() {
      this.getInfo()
      // console.log(this.devotokens);
     // this._i18n.locale = this.$storage.get("lang");
     // this.langIndex = this.lang == "zh" ? 1 : 0;
      setTimeout(()=>{
        this.asideFlags = this.shows
      },100)
    },
    methods: {
      //获取用户信息
      getInfo(){

       // this.$axios.get(this.$api.userinfo).then((res) => {
       // this.jhshow = res.data.active == 1? true : false;
       // this.jhshows = res.data.active == 1? false: true
      // });
        this.$axios.get(this.$api.userinfo).then(res => {
            this.jhshowt = res.data.active == 1? true :false
       })
      },
      //跳转路由
      toRouter(label){
        //邀请页
        if(label == '/invite'){
          if(this.jhshowt){
            this.$router.push(label);
            return
          }else{
            this.$toast(this.$t("index.userFirst"));
          }
        }
        //质押页
        if(label == '/pledge'){
          if(this.jhshowt){
            this.$router.push(label);
            return
          }else{
            this.$toast(this.$t("index.userFirst"));
          }
        }
        //挖矿页
        if(label == '/mining'){
          if(this.jhshowt){
            this.$router.push(label);
            return
          }else{
            this.$toast(this.$t("index.userFirst"));
          }
        }
      },
      onClickOverlay(){
        setTimeout(()=>{
          this.$emit('close')
        },1000)
      },
      asideShow(){
        this.$emit('asideFlagShow',false)
      },
      leftClick() {
        if (this.isGoBack) {
          this.$router.back();
        } else {
          this.$emit("leftClick");
        }
      },
      rightClick() {
        this.$emit("rightClick");
      },
      //选择语言
      onSelectLang(e) {
        this.langIndex = e.index;
        this._i18n.locale = e.index == 0 ? "en" : "zh";
        this.$storage.set("lang", e.index == 0 ? "en" : "zh");
        this.$store.commit("setLang", e.index == 0 ? "en" : "zh");
        this.reload()
      },
      //连接钱包成功后的选择
      onSelect(e) {
        // console.log(e);
        if (this.isLinkBsc) {
          //已登入BSC
          if (e.index == 0) {
            this.$router.push("/myNFTcard");
          }
        }
        if (e.index == 1) {
          //退出
          this.$store.commit("setwalletAddress", "");
          this.$store.commit("setDevotokens", "");
          this.$storage.remove("devotokens");
          this.$store.commit("setLinkBsc", false);
          this.$storage.clear()
          this.reload();
        }
      },
      //连接钱包
      ConnectWallet() {
        this.connectMetaMask();
      },
      toindex() {
        this.$router.push("/index");
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .metamask{
    height: 32px;
background: #114996;
border-radius: 5px;
font-size: 12px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;margin-left: 21px;
  }
  .navbar {
    padding: 15px 16px;
    background: #fff;
   margin-bottom:24px;
  }
  .list{
    .cell-title{
        font-size: 15px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;

    }
    /deep/.van-cell::after{

border-bottom: 1px solid #EAEAEA;
}
  }
  .right_connect {
    height: 30px;
    border-radius: 24px;
  }
  .right_lang {
    height: 32px;
  }
  .connect-btn{
    .van-button__text{
      display: flex;
      align-items: center;
    }
  }
  .vanCell{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    p{
      font-size: 15px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;
    }
  }
  .totalAmount{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  