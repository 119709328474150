<template>
  <div class="content" style="min-height: 100vh">
    <navbar @asideShow="asideFlagFun"></navbar>
    <!--<div v-if="flag" style="display:flex;background-color:#EDE6FF;padding:8px 10px;margin-top:4px;">
      <span>
        <van-image
          width="15px"
          height="15px"
          :src="require('@/assets/cls/info.png')"
        />
      </span>
      <span style="color:#8F6EFD;font-size:13px;margin-left:4px;margin-top:2px;">
        <marquee class = "marquee" behavior="scroll" direction="left">{{ $t("pledge.notification") }}</marquee>
      </span>
    </div>-->
    <div class="main">
      <div class="list" v-if="flag">

        <!-- 24h -->
        <!--<div class="item-box">
          <div class="title">
            <div>{{ $t("pledge.hourContract") }}</div>
          </div>
          <div class="item">
            <div class="name">
              <van-image
                width="20px"
                height="20px"
                :src="require('@/assets/cls/usdt-logo.png')"
              />
              <span>USDT</span>
            </div>
            <div class="contract">
              <div>
                <div>{{ $t("pledge.dailyReta") }}</div>
                <div style="font-weight: bold; color: #8f93fb; margin-top: 5px">
                  {{ msg.contract_interest_rate_24h }}%
                </div>
              </div>
              <div
                v-if="!pledgeRecord24h | (pledgeRecord24h.length == 0)"
                :class="'van_button' + (pledge_switch === '1' ? ' disabled' : '')"
                @click="pledge_switch !== '1' ? popupShowHandle('1') : ''"
              >
                {{ $t("pledge.start") }}
              </div>
              <van-button
                :disabled="pledgeRecord24h.status == 1"
                size="small"
                v-else
                color="#67C23A"
                @click="
                  extractHandle(
                    pledgeRecord24h.amount,
                    pledgeRecord24h.id,
                    '24h'
                  )
                "
                class="bold"
                >{{ $t("pledge.extract") }}</van-button
              >
            </div>
            <div class="detail" v-if="detail24h && pledgeRecord24h">
              <div class="top">
                <div>
                  <div>{{ time }}</div>
                  <div>{{ $t("pledge.countdown") }}</div>
                </div>
                <div>
                  <van-checkbox
                    checked-color="#7857fe"
                    v-model="checkboxVal"
                    icon-size="16px"
                    @change="checkboxChange(checkboxVal, pledgeRecord24h.id)"
                    >{{ $t("pledge.reinvestment") }}</van-checkbox
                  >
                  <van-popover v-model="showPopover" trigger="click">
                    <div
                      class="tips"
                      style="
                        background: #ffffff;
                        box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
                        border-radius: 10px;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        line-height: 18px;
                        padding: 14px 20px;
                      "
                    >
                      {{ $t("pledge.reinvestmentText") }}
                    </div>
                    <template #reference>
                      <div>
                        <van-image
                          width="15px"
                          height="15px"
                          :src="require('@/assets/cls/question.png')"
                        />
                      </div>
                    </template>
                  </van-popover>
                </div>
              </div>
              <div class="list">
                <div class="item_box">
                  <div>${{ pledgeRecord24h.amount }}</div>
                  <div>{{ $t("pledge.myPledge") }}</div>
                </div>
                <div class="item_box">
                  <div>${{ pledgeRecord24h.total_earn }}</div>
                  <div>{{ $t("pledge.GrossIncome") }}</div>
                </div>
                <div class="item_box">
                  <div>{{ pledgeRecord24h.time_count }}</div>
                  <div>{{ $t("pledge.PledgedayNum") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="pledgeRecord24h | (pledgeRecord24h.length != 0)"
              class="detail-btn"
              @click="detail24h = !detail24h"
            >
              {{ $t("pledge.details") }}
              <van-icon
                v-if="!detail24h"
                style="margin-left: 6px"
                name="arrow-down"
              />
              <van-icon
                style="margin-left: 6px"
                name="arrow-up"
                v-if="detail24h"
              />
            </div>
          </div>
        </div>-->
        <!-- 7day -->
        <div class="item-box">
          <div class="title">
            <div>{{ $t("pledge.day7Contract") }}</div>
          </div>
          <div class="item">
            <div class="name">
              <van-image
                width="20px"
                height="20px"
                :src="require('@/assets/cls/usdt-logo.png')"
              />
              <span>USDT</span>
            </div>
            <div class="contract">
              <div>
                <div>{{ $t("pledge.dailyReta") }}</div>
                <div style="font-weight: bold; color: #7857fe; margin-top: 5px">
                  {{ msg.contract_interest_rate_7d }}%
                  <span style="margin-left:3px;font-weight:normal;color: #787878;">({{ $t("pledge.pledge7DayNotice") }})</span>
                </div>
              </div>
              <div
                :class="'van_button' + (pledge_switch === '1' || pledgeRecord7Day.length > 0 ? ' disabled' : '')"
                @click="pledge_switch === '1' || pledgeRecord7Day.length > 0 ? '' : popupShowHandle('1')"
              >
                {{ $t("pledge.start") }}
              </div>
            </div>
            <div
              class="detail"
              style="padding-top: 15px"
              v-show="detail7d && pledgeRecord7Day.length != 0"
              v-for="(item, i) in pledgeRecord7Day"
              :key="item.id"
            >
              <div class="top">
                <div>
                  <div style="display: flex">
                    <div class="index">{{ i + 1 }}</div>
                    <div style="margin-left: 10px" v-if="item.status == 1">
                      <div>{{ timeDown(item.s_time) }}</div>
                      <div>{{ $t("pledge.countdown") }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 1" style="align-items: start;">
                  <van-checkbox
                    checked-color="#7857fe"
                    v-model="item.is_auto"
                    icon-size="16px"
                    @change="checkboxChange(item.is_auto, item.id)"
                    >{{ $t("pledge.reinvestment") }}</van-checkbox
                  >
                  <van-popover v-model="item.reinvestment_popover" trigger="click">
                    <div
                      class="tips"
                      style="
                        background: #ffffff;
                        box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
                        border-radius: 10px;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        line-height: 18px;
                        padding: 14px 20px;
                      "
                    >
                      {{ $t("pledge.reinvestmentText") }}
                    </div>
                    <template #reference>
                      <div>
                        <van-image
                          width="15px"
                          height="15px"
                          style="margin-top:2px;"
                          :src="require('@/assets/cls/question.png')"
                        />
                      </div>
                    </template>
                  </van-popover>
                </div>
                <div v-else-if="item.status == 2" class="btnstyle">
                  <van-button
                    :disabled="item.status == 1"
                    size="small"
                    color="#67C23A"
                    class="bold"
                    @click="extractHandle(item.amount, item.id)"
                    >{{ $t("pledge.extract") }}</van-button
                  >
                </div>
              </div>
              <div class="list">
                <div class="item_box">
                  <div>${{ item.amount }}</div>
                  <div>{{ $t("pledge.myPledge") }}</div>
                </div>
                <div class="item_box">
                  <div>${{ item.total_earn }}</div>
                  <div>{{ $t("pledge.income") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="pledgeRecord7Day.length != 0"
              class="detail-btn"
              @click="detail7d = !detail7d"
            >
              {{ $t("pledge.details") }}
              <van-icon
                v-if="!detail7d"
                style="margin-left: 6px"
                name="arrow-down"
              />
              <van-icon
                style="margin-left: 6px"
                name="arrow-up"
                v-if="detail7d"
              />
            </div>
          </div>
        </div>
        <!-- 15day -->
        <div class="item-box">
          <div class="title">
            <div>{{ $t("pledge.day15Contract") }}</div>
          </div>
          <div class="item">
            <div class="name">
              <van-image
                width="20px"
                height="20px"
                :src="require('@/assets/cls/usdt-logo.png')"
              />
              <span>USDT</span>
            </div>
            <div class="contract">
              <div>
                <div>{{ $t("pledge.dailyReta") }}</div>
                <div style="font-weight: bold; color: #7857fe; margin-top: 5px">
                  {{ msg.contract_interest_rate_15d }}%
                  <span style="margin-left:3px;font-weight:normal;color: #787878;">({{ $t("pledge.pledge15DayNotice") }})</span>
                </div>
              </div>
              <div
                :class="'van_button' + (pledge_switch === '1' || pledgeRecord15Day.length > 0 ? ' disabled' : '')"
                @click="pledge_switch === '1' || pledgeRecord15Day.length > 0 ? '' : popupShowHandle('2')"
              >
                {{ $t("pledge.start") }}
              </div>
            </div>
            <div
              class="detail"
              style="padding-top: 15px"
              v-show="detail15d && pledgeRecord15Day.length != 0"
              v-for="(item, i) in pledgeRecord15Day"
              :key="item.id"
            >
              <div class="top">
                <div>
                  <div style="display: flex">
                    <div class="index">{{ i + 1 }}</div>
                    <div style="margin-left: 10px" v-if="item.status == 1">
                      <div>{{ timeDown(item.s_time) }}</div>
                      <div>{{ $t("pledge.countdown") }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 1">
                  <van-checkbox
                    checked-color="#7857fe"
                    v-model="item.is_auto"
                    icon-size="16px"
                    @change="checkboxChange(item.is_auto, item.id)"
                    >{{ $t("pledge.reinvestment") }}</van-checkbox
                  >
                  <van-popover v-model="item.reinvestment_popover" trigger="click">
                    <div
                      class="tips"
                      style="
                        background: #ffffff;
                        box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
                        border-radius: 10px;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        line-height: 18px;
                        padding: 14px 20px;
                      "
                    >
                      {{ $t("pledge.reinvestmentText") }}
                    </div>
                    <template #reference>
                      <div>
                        <van-image
                          width="15px"
                          height="15px"
                          :src="require('@/assets/cls/question.png')"
                        />
                      </div>
                    </template>
                  </van-popover>
                </div>
                <div v-else-if="item.status == 2" class="btnstyle">
                  <van-button
                    :disabled="item.status == 1"
                    size="small"
                    color="#67C23A"
                    class="bold"
                    @click="extractHandle(item.amount, item.id)"
                    >{{ $t("pledge.extract") }}</van-button
                  >
                </div>
              </div>
              <div class="list">
                <div class="item_box">
                  <div>${{ item.amount }}</div>
                  <div>{{ $t("pledge.myPledge") }}</div>
                </div>
                <div class="item_box">
                  <div>${{ item.total_earn }}</div>
                  <div>{{ $t("pledge.income") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="pledgeRecord15Day.length != 0"
              class="detail-btn"
              @click="detail15d = !detail15d"
            >
              {{ $t("pledge.details") }}
              <van-icon
                v-if="!detail15d"
                style="margin-left: 6px"
                name="arrow-down"
              />
              <van-icon
                style="margin-left: 6px"
                name="arrow-up"
                v-if="detail15d"
              />
            </div>
          </div>
        </div>
        <!-- 30day -->
        <div class="item-box">
          <div class="title">
            <div>{{ $t("pledge.dayContract") }}</div>
          </div>
          <div class="item">
            <div class="name">
              <van-image
                width="20px"
                height="20px"
                :src="require('@/assets/cls/usdt-logo.png')"
              />
              <span>USDT</span>
            </div>
            <div class="contract">
              <div>
                <div>{{ $t("pledge.dailyReta") }}</div>
                <div style="font-weight: bold; color: #7857fe; margin-top: 5px">
                  {{ msg.contract_interest_rate_30d }}%
                  <span style="margin-left:3px;font-weight:normal;color: #787878;">({{ $t("pledge.pledge30DayNotice") }})</span>
                </div>
              </div>
              <div
                :class="'van_button' + (pledge_switch === '1' ? ' disabled' : '')"
                @click="pledge_switch !== '1' ? popupShowHandle('3') : ''"
              >
                {{ $t("pledge.start") }}
              </div>
            </div>
            <div
              class="detail"
              style="padding-top: 15px"
              v-show="detail30d && pledgeRecord30Day.length != 0"
              v-for="(item, i) in pledgeRecord30Day"
              :key="item.id"
            >
              <div class="top">
                <div>
                  <div style="display: flex">
                    <div class="index">{{ i + 1 }}</div>
                    <div style="margin-left: 10px" v-if="item.status == 1">
                      <div>{{ timeDown(item.s_time) }}</div>
                      <div>{{ $t("pledge.countdown") }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 1">
                  <van-checkbox
                    checked-color="#7857fe"
                    v-model="item.is_auto"
                    icon-size="16px"
                    @change="checkboxChange(item.is_auto, item.id)"
                    >{{ $t("pledge.reinvestment") }}</van-checkbox
                  >
                  <van-popover v-model="item.reinvestment_popover" trigger="click">
                    <div
                      class="tips"
                      style="
                        background: #ffffff;
                        box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
                        border-radius: 10px;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        line-height: 18px;
                        padding: 14px 20px;
                      "
                    >
                      {{ $t("pledge.reinvestmentText") }}
                    </div>
                    <template #reference>
                      <div>
                        <van-image
                          width="15px"
                          height="15px"
                          :src="require('@/assets/cls/question.png')"
                        />
                      </div>
                    </template>
                  </van-popover>
                </div>
                <div v-else-if="item.status == 2" class="btnstyle">
                  <van-button
                    :disabled="item.status == 1"
                    size="small"
                    color="#67C23A"
                    class="bold"
                    @click="extractHandle(item.amount, item.id)"
                    >{{ $t("pledge.extract") }}</van-button
                  >
                </div>
              </div>
              <div class="list">
                <div class="item_box">
                  <div>${{ item.amount }}</div>
                  <div>{{ $t("pledge.myPledge") }}</div>
                </div>
                <div class="item_box">
                  <div>${{ item.total_earn }}</div>
                  <div>{{ $t("pledge.income") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="pledgeRecord30Day.length != 0"
              class="detail-btn"
              @click="detail30d = !detail30d"
            >
              {{ $t("pledge.details") }}
              <van-icon
                v-if="!detail30d"
                style="margin-left: 6px"
                name="arrow-down"
              />
              <van-icon
                style="margin-left: 6px"
                name="arrow-up"
                v-if="detail30d"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modelBox"
      v-if="showModelBox"
      @click="showModelBox = false"
    ></div>
    <div class="screen-popup" v-if="showModelBox">
      <div class="title">{{ $t("pledge.PledgeAmount") }}</div>
      <div v-if="type == 3">
        <p class="fs-md m-b-20 t-center" style="font-size: 16px;font-weight: bold;">{{ iptVal }}U</p>
        <van-slider v-model="iptVal" :min="100" :max="5000" bar-height="5px" :step="100" />
        <p class="d-flex ai-center jc-between t-color9 fs-md m-t-10">
          <span>100U</span><span>5000U</span>
        </p>
      </div>
      <div v-else class="popupFlex">
        <div
          class="popupFlex_item"
          :style="showVal == 0 ? 'background:#ecedff' : 'background:white'"
          @click="toValue(10, 0)"
        >
          10USDT
        </div>
        <div
          class="popupFlex_item"
          :style="showVal == 1 ? 'background:#ecedff' : 'background:white'"
          @click="toValue(50, 1)"
        >
          50USDT
        </div>
        <div
          class="popupFlex_item"
          :style="showVal == 2 ? 'background:#ecedff' : 'background:white'"
          @click="toValue(100, 2)"
        >
          100USDT
        </div>
      </div>
      <div class="balance" style="width:100%;">
        <van-checkbox
          checked-color="#7857fe"
          v-model="checked"
          icon-size="16px"
        >
          <span style="white-space: nowrap">{{
            $t("pledge.reinvestment")
          }}</span>
        </van-checkbox>
      </div>
      <div class="btn" v-if="pledgeFlag">
        <van-button size="small" @click="showModelBox = false">{{
          $t("pledge.cancel")
        }}</van-button>
        <van-button
          color="#8f93fb"
          v-if="switchMT == 1"
          size="small"
          @click="sub"
        >
          {{ $t("pledge.submit") }}
        </van-button>
        <van-button
          color="#8f93fb"
          v-if="switchMT == 2"
          size="small"
          @click="subTw"
        >
          {{ $t("pledge.submit") }}
        </van-button>
      </div>
      <div class="btn" v-else>
        <van-button
          color="#8f93fb"
          size="small"
          round
          @click="onApprove"
          style="width: 100%"
          v-if="switchMT == 1"
        >
          {{ $t("pledge.authorization") }}
        </van-button>
        <van-button
          color="#8f93fb"
          size="small"
          round
          @click="onApproves"
          style="width: 100%"
          v-if="switchMT == 2"
        >
          {{ $t("pledge.authorization") }}
        </van-button>
      </div>
    </div>
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
// import VConsole from "../vconsole.js";
import { ethers } from "ethers";

import asidePopup from "@/components/common/asidePopup";
import abi_U from "@/common/abi/abi_U.json";
import abi_C from "../common/abi/abi_C.json";
import abi_G from "../common/abi/abi_G.json";
import moment from "moment/moment";
import BigNumber from "bignumber.js";

export default {
  components: { asidePopup },
  async mounted() {
    this.switchMT = window.localStorage.getItem("switchMT");
    this.$axios.get(this.$api.setting).then((res) => {
      this.Setting = res.data;
      // this.msgHandle(); //日利率
      this.settingHandle();
      this.getPledgeList();
      if (this.switchMT == 1) {
        this.getdecimals(); //查询精度
        this.getAppoveBlance(); //查询授权额度
      } else if (this.switchMT == 2) {
        this.getdecimal(); //查询精度
        this.getAppoveBlances(); //查询授权额度
      }
    });
    this.web3Utils = new this.$Web3(window.ethereum).utils
    const injectedProvider = await this.getTrustWalletFromWindow();
    //切换链ID
    injectedProvider.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)),
        },
      ],
    });

    this.countdownInterval = setInterval(() => {
      if (this.pledgeRecord7Day != null && typeof this.pledgeRecord7Day != 'undefined') {
        // const date7d = JSON.parse(JSON.stringify(this.pledgeRecord7Day));
        this.pledgeRecord7Day.forEach((item, index) => {
          if (this.pledgeRecord7Day[index] != null && typeof this.pledgeRecord7Day[index].s_time != 'undefined') {
            this.pledgeRecord7Day[index].s_time--;
          }
        });
      }
      if (this.pledgeRecord15Day != null && typeof this.pledgeRecord15Day != 'undefined') {
        // const date15d = JSON.parse(JSON.stringify(this.pledgeRecord15Day));
        this.pledgeRecord15Day.forEach((item, index) => {
          if (this.pledgeRecord15Day[index] != null && typeof this.pledgeRecord15Day[index].s_time != 'undefined') {
            this.pledgeRecord15Day[index].s_time--;
          }
        });
      }
      if (this.pledgeRecord30Day != null && typeof this.pledgeRecord30Day != 'undefined') {
        // const date30d = JSON.parse(JSON.stringify(this.pledgeRecord30Day));
        this.pledgeRecord30Day.forEach((item, index) => {
          if (this.pledgeRecord30Day[index] != null && typeof this.pledgeRecord30Day[index].s_time != 'undefined') {
            this.pledgeRecord30Day[index].s_time--;
          }
        });
      }
    }, 1000);
  },
  data() {
    return {
      flag: false, //解决created异步 数据延迟报错问题
      asideFlag: false,
      popupShow: false,
      value: 100,
      iptVal: 10,
      checked: true,
      detail24h: false,
      detail7d: false,
      detail15d: false,
      detail30d: false,
      showPopover: false,
      pledgeRecord24h: null,
      pledgeRecord7Day: [],
      pledgeRecord15Day: [],
      pledgeRecord30Day: [],
      Setting: null,
      msg: {},
      type: "",
      time: "",
      flag: false,
      flag1: false,
      checkboxVal: 0,
      time2: "",
      contract_address: "",
      wallet_address: "",
      balance: 0,
      switchMT: null,
      decimals: 0,
      pledge_contract: "",
      line: "",
      pledgeFlag: true,
      v3LPtoken_contract: "",
      busd_contrac: "",
      withdraw_contract: "",
      showVal: 0,
      showModelBox: false,
      pledge_switch: null, //质押开关
      web3Utils: null,
      countdownInterval: null
    };
  },
  computed: {
    balances() {
      if (this.decimals) {
        let balance = BigNumber(this.balance)
          .div(Math.pow(10, this.decimals))
          .toFixed();
        return balance;
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.countdownInterval)
  },
  methods: {
    getTrustWalletFromWindow() {
      const isTrustWallet = (ethereum) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;
        return trustWallet;
      };

      const injectedProviderExist =
        typeof window !== "undefined" && typeof window.ethereum !== "undefined";

      if (!injectedProviderExist) {
        return null;
      }

      if (isTrustWallet(window.ethereum)) {
        return window.ethereum;
      }

      if (window.ethereum?.providers) {
        return window.ethereum.providers.find(isTrustWallet) ?? null;
      }
      return window["trustwallet"] ?? null;
    },
    //提取回调TW
    async contract_redemptions(res, type) {
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );
      const signer = ethersProvider.getSigner();
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let that = this;
      let contract = new ethers.Contract(
        this.withdraw_contract,
        abi_G,
        ethersProvider
      );
      contract
        .connect(signer)
        .redemption(res.random, res.token, res.amount, res.sign)
        .then(
          (res) => {
            this.$toast.clear();
            that.$toast(that.$t("pledge.wait"));
            setTimeout(() => {
              if (type) {
                that.pledgeRecord24h = null;
              } else {
                that.getPledgeList();
              }
            }, 10000);
          },
          (err) => {
            this.$toast.clear();
            that.$toast(that.$t("pledge.redemptionfail"));
          }
        );
    },
    // 质押TW
    async subTw() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );

      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let that = this;
      if (Number(this.iptVal) > Number(this.balance)) {
        this.$toast(this.$t("pledge.nobalance"));
        return;
      }
      if (Number(this.iptVal) > Number(this.line)) {
        this.$toast(this.$t("pledge.insufficient"));
        this.pledgeFlag = false;
        return;
      }
      let repeatType = "";
      if (this.type == 1) {
        if (this.checked) {
          repeatType = 2;
        } else {
          repeatType = 1;
        }
      } else if (this.type == 2) {
        if (this.checked) {
          repeatType = 4;
        } else {
          repeatType = 3;
        }
      } else if (this.type == 3) {
        if (this.checked) {
          repeatType = 6;
        } else {
          repeatType = 5;
        }
      }
      let amount = BigNumber(this.iptVal).times(Math.pow(10, 18)).toFixed();
      let contract = new ethers.Contract(
        this.pledge_contract,
        abi_C,
        ethersProvider
      );
      const signer = ethersProvider.getSigner();
      contract
        .connect(signer)
        .pledge(
          amount,
          repeatType.toString()
        )
        .then(
          (res) => {
            this.showModelBox = false; //关闭弹框
            that.popupShow = false;
            that.getPledgeList(); //获取质押列表
            that.getdecimal(); //查询精度
            that.getAppoveBlances();
            this.$toast.clear();
            that.$toast(that.$t("pledge.succ"));
          },
          (err) => {
            console.error('err', err)
            this.$toast.clear();
            that.$toast(that.$t("pledge.fail"));
          }
        );
    },
    //TW查询精度
    async getdecimal() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );
      const contracts = new ethers.Contract(
        this.contract_address,
        abi_U,
        ethersProvider
      );
      const signer = ethersProvider.getSigner();
      //查询余额
      const con = await contracts.balanceOf(this.wallet_address);
      this.balance = ethers.BigNumber.from(con);
      this.balance = this.balance / Math.pow(10, 18)
      this.balance = this.balance.toString();
      //查询精度
      const decimasls = await contracts.decimals();
      this.decimals = ethers.BigNumber.from(decimasls).toString();
    },
    // TW授权
    async onApproves() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );

      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let amount = BigNumber(10000000).times(Math.pow(10, 18)).toFixed();
      // let amount = BigNumber(1).times(Math.pow(2, 256) - 1).toFixed();
      let contract = new ethers.Contract(
        this.contract_address,
        abi_U,
        ethersProvider
      );
      const signer = ethersProvider.getSigner();
      contract
        .connect(signer)
        .approve(this.pledge_contract, amount)
        .then(
          (res) => {
            this.getAppoveBlances();
            setTimeout(() => {
              this.$toast.clear();
            }, 20000);
          },
          (err) => {
            //授权失败
            this.$toast(this.$t("public.approveFail"));
          }
        );
    },
    //TW查询授权额度
    async getAppoveBlances() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );
      const contracts = new ethers.Contract(
        this.contract_address,
        abi_U,
        ethersProvider
      );
      const con = await contracts.allowance(
        this.wallet_address,
        this.pledge_contract
      );
      let line = ethers.BigNumber.from(con).toString();
      this.line = BigNumber(line).div(Math.pow(10, 18)).toFixed();
      this.pledgeFlag = true;
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    //选择金额
    toValue(val, num) {
      this.showVal = num;
      this.iptVal = val;
    },
    //查询精度
    getdecimals() {
      let contract = new web3.eth.Contract(abi_U, this.contract_address, {
        from: this.wallet_address,
      });
      // 余额
      contract.methods
        .balanceOf(this.wallet_address)
        .call({ from: this.wallet_address })
        .then((res) => {
          this.balance = res;
        });
      // 精度
      contract.methods
        .decimals()
        .call({ from: this.wallet_address }, (err, res) => {
          this.decimals = res;
        });
    },
    //查询授权额度
    async getAppoveBlance() {
      let contract = new web3.eth.Contract(abi_U, this.contract_address);
      contract.methods
        .allowance(this.wallet_address, this.pledge_contract)
        .call({}, (err, res) => {
          let line = BigNumber(res).div(Math.pow(10, this.decimals)).toFixed();
          this.line = line;
          this.pledgeFlag = true;
        });
    },
    //授权
    async onApprove() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let amount = BigNumber(10000000)
        .times(Math.pow(10, this.decimals))
        .toFixed();
      // let amount =BigNumber(115792089237316195423570985008687907853269984665640564039457584007913129639935).toFixed()
      let contract = new web3.eth.Contract(abi_U, this.contract_address);
      contract.methods
        .approve(this.pledge_contract, amount)
        .send({ from: this.wallet_address }, (err, res) => {
          if (res) {
          } else {
            //授权失败
            this.$toast(this.$t("public.approveFail"));
          }
        })
        .on("receipt", (receipt) => {
          this.getAppoveBlance();
          this.$toast.clear();
        });
    },
    settingHandle() {
      this.wallet_address = this.$store.state.walletAddress;
      // let setting = JSON.parse(this.$store.state.setting);
      this.Setting.forEach((item) => {
        if (item.key == "usdt_contract_address") {
          this.contract_address = item.value;
        }
        if (item.key == "pledge_contract") {
          this.pledge_contract = item.value;
        }
        if (item.key == "v3LPtoken") {
          this.v3LPtoken_contract = item.value;
        }
        if (item.key == "busd_contract") {
          this.busd_contrac = item.value;
        }
        if (item.key == "withdraw_contract") {
          this.withdraw_contract = item.value;
        }
        if (item.key == "pledge_switch") {
          this.pledge_switch = item.value;
        }
        if (item.key == "24h_contract_interest_rate") {
          this.msg.contract_interest_rate_24h = item.value;
        }
        if (item.key == "7day_contract_interest_rate") {
          this.msg.contract_interest_rate_7d = item.value;
        }
        if (item.key == "15day_contract_interest_rate") {
          this.msg.contract_interest_rate_15d = item.value;
        }
        if (item.key == "30day_contract_interest_rate") {
          this.msg.contract_interest_rate_30d = item.value;
        }
      });
    },
    timeDown2() {
      if (!this.pledgeRecord24h) {
        return;
      }
      // let t = this.pledgeRecord24h.s_time; //结束时间
      // let nt = this.pledgeRecord24h.start_time //开始时间
      // t = moment(t).format("YYYY/MM/DD HH:mm:ss");
      // nt = moment(nt).format("YYYY/MM/DD HH:mm:ss");
      // const endTime = new Date(t);
      // const nowTime = new Date(nt);
      // let leftTime = parseInt((endTime.getTime() - nowTime.getTime()) / 1000);

      let d = parseInt(this.pledgeRecord24h.s_time / (24 * 60 * 60));
      let h = this.formate(
        parseInt((this.pledgeRecord24h.s_time / (60 * 60)) % 24)
      );
      let m = this.formate(parseInt((this.pledgeRecord24h.s_time / 60) % 60));
      let s = this.formate(parseInt(this.pledgeRecord24h.s_time % 60));
      if (this.pledgeRecord24h.s_time <= 0) {
        this.flag1 = true;
        this.time = this.$t("pledge.countdownGo");
      } else {
        this.time = `${h}:${m}:${s}`; // 需要修改时间样式的话 ，比如需要什么30分钟倒计时，就只保留分和秒
      }
    },
    buling(num) {
      if (num.length == 1) {
        return "0" + num;
      } else {
        return num;
      }
    },
    getRemainingHours(dateTime) {
      return parseInt(dates / (60 * 60));
    },
    timeDown(dates) {
      // let add = new Date().toLocaleString();
      // let add1 = add.split(" ")[0].split("/");
      // add1 = add1
      //   .map((item) => {
      //     return this.buling(item);
      //   })
      //   .join("/");
      // let add2 = add.split(" ")[1].split("/");
      // add = add1 + " " + add2;
      // dates = moment(dates).format("YYYY/MM/DD HH:mm:ss");
      // if (dates < add) {
      //   //小于当前时间清除定时器
      //   this.flag = true;
      // }
      // var date1 = new Date(dates);
      // var time1 = date1.getTime();
      // var date2 = new Date();
      // var time2 = date2.getTime();

      // var difftime = (time1 - time2) / 1000;
      // var days = parseInt(difftime / 86400); // 天  24*60*60*1000
      // var hours = parseInt(difftime / 3600) - 24 * days; // 小时 60*60 总小时数-过去的小时数=现在的小时数
      // var minutes = parseInt((difftime % 3600) / 60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
      // var seconds = parseInt(difftime % 60); // 以60秒为一整份 取余 剩下秒数
      let d = parseInt(dates / (24 * 60 * 60));
      let h = this.formate(parseInt((dates / (60 * 60)) % 24));
      let m = this.formate(parseInt((dates / 60) % 60));
      let s = this.formate(parseInt(dates % 60));

      if (d > 0) {
        return `${d}${this.$t("pledge.days")}${h}${this.$t("pledge.hours")}`;
      } else {
        return `${h}:${m}:${s}`; // 需要修改时间样式的话 ，比如需要什么30分钟倒计时，就只保留分和秒
      }
    },
    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    popupShowHandle(type) {
      if (this.pledge_switch == 1) {
        this.$toast(this.$t("pledge.noShow"));
        return;
      }
      this.showVal = 0;
      this.iptVal = 10;
      this.showModelBox = true;
      this.type = type;
    },
    iptHandle(val) {
      this.value = Number(val);
      if (this.type == 1) {
        if (this.value > 1000) {
          this.iptVal = 1000;
        }
      } else {
        if (this.value > 100000) {
          this.iptVal = 100000;
        }
      }
    },
    // 提取
    extractHandle(val, pledge_id, type) {
      this.$axios.post(this.$api.redeemSign, { pledge_id }).then((res) => {
        if (this.switchMT == 1) {
          this.contract_redemption(res.data, type);
        } else if (this.switchMT == 2) {
          this.contract_redemptions(res.data, type);
        }
      });
    },
    async contract_redemption(res, type) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let that = this;
      let contract = new web3.eth.Contract(abi_G, this.withdraw_contract);
      contract.methods
        .redemption(res.random, res.token, res.amount, res.sign)
        .send({ from: this.wallet_address }, (err, res) => {
          if (res) {
          } else {
            this.$toast.clear();
            that.$toast(that.$t("pledge.redemptionfail"));
          }
        })
        .on("receipt", (receipt) => {
          this.$toast.clear();
          that.$toast(that.$t("pledge.wait"));
          setTimeout(() => {
            if (type) {
              that.pledgeRecord24h = null;
            } else {
              that.getPledgeList();
            }
          }, 10000);
        })
        .on("error", (confirmation) => {
          this.$toast.clear();
          that.$toast(that.$t("pledge.redemptionfail"));
        });
    },
    // 质押
    async sub() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let that = this;
      if (Number(this.iptVal) > Number(this.line)) {
        this.$toast(this.$t("pledge.insufficient"));
        this.pledgeFlag = false;
        return;
      }
      let repeatType = "";
      if (this.type == 1) {
        if (this.checked) {
          repeatType = 2;
        } else {
          repeatType = 1;
        }
      } else if (this.type == 2) {
        if (this.checked) {
          repeatType = 4;
        } else {
          repeatType = 3;
        }
      } else if (this.type == 3) {
        if (this.checked) {
          repeatType = 6;
        } else {
          repeatType = 5;
        }
      }
      let amount = BigNumber(this.iptVal)
        .times(Math.pow(10, this.decimals))
        .toFixed();
      let contract = new web3.eth.Contract(abi_C, this.pledge_contract);
      contract.methods
        .pledge(
          this.contract_address,
          amount,
          repeatType.toString()
        )
        .send({ from: this.wallet_address }, (err, res) => {
          if (res) {
          } else {
            this.$toast.clear();
            that.$toast(that.$t("pledge.fail"));
          }
        })
        .on("receipt", (receipt) => {
          this.getAppoveBlance();
          this.showModelBox = false; //关闭弹框
          that.popupShow = false;
          that.getPledgeList(); //获取质押列表
          that.getdecimals(); //查询精度
          that.getAppoveBlance();
          this.$toast.clear();
          that.$toast(that.$t("pledge.succ"));
        })
        .on("error", (confirmation) => {
          this.$toast.clear();
          that.$toast(that.$t("pledge.fail"));
        });
    },
    msgHandle() {
      // let setting = JSON.parse(this.$store.state.setting);
      this.Setting.forEach((item) => {
        if (item.key == "24h_contract_interest_rate") {
          this.msg.contract_interest_rate_24h = item.value;
        }
        if (item.key == "7day_contract_interest_rate") {
          this.msg.contract_interest_rate_7d = item.value;
        }
        if (item.key == "15day_contract_interest_rate") {
          this.msg.contract_interest_rate_15d = item.value;
        }
        if (item.key == "30day_contract_interest_rate") {
          this.msg.contract_interest_rate_30d = item.value;
        }
      });
    },
    //质押列表
    getPledgeList() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "",
      });
      this.$axios.get(this.$api.pledgeList, {}).then((res) => {
        // this.pledgeRecord24h = res.data.pledgeRecord24h;
        // if (this.pledgeRecord24h) {
        //   this.checkboxVal = this.pledgeRecord24h.is_auto;
        // }
        // let time1 = setInterval(() => {
        //   if (this.flag1 == true) {
        //     clearInterval(time1);
        //   }
        //   this.timeDown2();
        //   this.pledgeRecord24h.s_time--;
        // }, 1000);

        for (let i in res.data.pledgeRecord7Day) {
          res.data.pledgeRecord7Day[i].reinvestment_popover = false
        }
        this.pledgeRecord7Day = res.data.pledgeRecord7Day;

        for (let i in res.data.pledgeRecord15Day) {
          res.data.pledgeRecord15Day[i].reinvestment_popover = false
        }
        this.pledgeRecord15Day = res.data.pledgeRecord15Day;

        for (let i in res.data.pledgeRecord30Day) {
          res.data.pledgeRecord30Day[i].reinvestment_popover = false
        }
        this.pledgeRecord30Day = res.data.pledgeRecord30Day;

        this.$toast.clear();
        this.flag = true;
      });
    },
    // {{ $t("pledge.auto") }}
    checkboxChange(e, pledge_id) {
      if (e === true || e === false) {
        this.$axios
          .post(this.$api.cancelAuto, {
            pledge_id,
            is_auto: (e === true ? 1 : 0),
          })
          .then((res) => {
            this.getPledgeList();
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 26px 15px;
  .list {
    .item-box {
      margin-bottom: 15px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 11px;
        div:first-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .arrow {
          width: 30px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background: #ecf5ff;
          border: 1px solid #00a8ff;
          border-radius: 5px;
        }
      }
      .item-box_bottom_border {
        border-radius: 30px;
        border-bottom: 1px solid #000;
        background-color: #7857fe;
        padding-bottom: 5px;
      }
      .item {
        background: linear-gradient(-90deg, #f7f2ff, #d3c2ff);
        border: 1px solid #000000;
        box-shadow: 1px 1px 0px 0px #000000;
        border-radius: 10px;

        box-sizing: border-box;
        padding: 15px 0px 0px 0px;
        position: relative;
        .name {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
          margin: 0 15px;
          margin-bottom: 11px;
          span {
            margin-left: 6px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
        }
        .contract {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 15px;
          margin-bottom: 18px;
          > div:first-child {
            div {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }
          }
          button {
            width: 87px;
            height: 32px;
            border: 1px solid #000 !important;
            border-radius: 50px;
            background: #feb420 !important;
            color: black !important;
          }
        }
      }
      .detail-btn {
        // position: absolute;
        // left: 0;
        // bottom: -28px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        // border-radius: 0px 0px 10px 10px;
        background: #e5e6ff;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #7857fe;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #000;
      }
      .detail {
        padding: 17px 10px;
        margin: 0 15px;
        margin-bottom: 19px;
        background: #e5e6ff;
        border-radius: 15px;
        border: 1px solid #000;
        position: relative;
        .index {
          // position: absolute;
          // top: 0;
          // left: 0;
          width: 32px;
          text-align: center;
          line-height: 19px;
          height: 32px;
          background: #7857fe;
          border-radius: 5px 0px 5px 0px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff !important;
          border-radius: 50%;
          line-height: 32px;
          border: 1px solid #000;
        }
        .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          > div:first-child {
            div:first-child {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #f29810;
              margin-bottom: 7px;
            }
            div:last-child {
              font-size: 10px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
          }
          > div:last-child {
            display: flex;
            align-items: center;
            /deep/.van-checkbox__label {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              margin-right: 5px;
            }
          }
        }
        .list {
          display: flex;

          .item_box {
            flex: 1;
            margin-right: 5%;
            background: white;
            border-radius: 5px;
            padding: 11px 0 11px 11px;

            div:first-child {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #7857fe;
              margin-bottom: 5px;
            }
            div:last-child {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: black;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }

        /deep/.van-checkbox {
          display: flex;
          justify-content: center;

          .van-checkbox__label {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }
        }
        /deep/ .van-checkbox__icon {
          border: 1px solid #ccc;
          border-radius: 50%;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          text-align: center;
          width: 16px;
          height: 16px;
        }
        /deep/ .van-icon {
          font-size: 8px;
          margin: 0 auto;
          margin-top: 50%;
          transform: translateY(-50%);
          border: none;
        }
        /deep/ .van-icon:before {
          // color: #7857fe;
          display: none;
        }
      }
    }
  }
}
.screen-popup {
  width: 261px;
  // height: 211px;
  border: 1px solid #000;
  border-radius: 10px;
  background: white;
  padding: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  .title {
    text-align: center;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 29px;
  }
  .subhead {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }
  .van-slider {
    margin: 22px 0 12px;
  }
  .range {
    display: flex;
    justify-content: space-between;
    div {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
  .input {
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 5px;

    .name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  /deep/.van-checkbox {
    display: flex;
    justify-content: center;

    .van-checkbox__label {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
  }
  .balance {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    button {
      border-radius: 5px;
      width: 49%;
      border: 1px solid #000 !important;
      background: white;
      font-size: 12px;
      &:first-child {
        margin-right: 2%;
      }
    }
  }
  .popupFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .popupFlex_item {
      width: 70px;
      height: 32px;
      border: 1px solid #000;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      color: black;
      font-size: 12px;
      font-weight: bold;
    }
  }
  /deep/ .van-checkbox__icon {
    border: 1px solid #ccc;
    border-radius: 50%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    width: 16px;
    height: 16px;
  }
  /deep/ .van-icon {
    font-size: 8px;
    margin: 0 auto;
    margin-top: 50%;
    transform: translateY(-50%);
    border: none;
  }
  /deep/ .van-icon:before {
    // color: #7857fe;
    display: none;
  }
}

.modelBox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}
.van_button {
  min-width: 87px;
  height: 32px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #8f6ffd;
  box-shadow: inset 0 0px 5px rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.van_button.disabled {
  background-color: #787878;
}
.listt {
  max-height: 384px;
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.btnstyle {
  button {
    width: 87px;
    height: 32px;
    border: 1px solid #000 !important;
    border-radius: 50px;
    background: #feb420 !important;
    color: black !important;
  }
}
.bold {
  min-width: 87px;
  height: 32px;
  border: 1px solid #000;
  border-radius: 5px !important;
  background: #feb420 !important;
  box-shadow: inset 0 0px 10px rgba(255, 255, 255, 1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
</style>